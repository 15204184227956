<template>
  <div class="mx-4 my-4 md:mx-8">
    <Breadcrumb :links="breadcrumb" />
    <br />
    <h1 class="text-3xl mb-5">Auditoria</h1>
    <form class="mb-2">
      <div class="grid md:grid-cols-6 gap-3">
        <div>
          <label for="de" class="mt-2 block text-sm font-medium">A partir de</label>
          <input
            v-model="form.de"
            type="datetime-local"
            name="de"
            id="de"
            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label for="ate" class="mt-2 block text-sm font-medium">Até</label>
          <input
            v-model="form.ate"
            type="datetime-local"
            name="ate"
            id="ate"
            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label for="ate" class="mt-2 block text-sm font-medium">Ação</label>
          <select
            class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            name="acao"
            id="acao"
            v-model="form.acao"
          >
            <option value="">Todos</option>
            <option value="login">Login</option>
            <option value="upload">Upload</option>
            <option value="campanhas">Campanha</option>
            <option value="chats">Chats</option>
            <option value="respostas">Respostas</option>
            <option value="operadores">Operadores</option>
            <option value="relatório">Relatório</option>
            <option value="consultaNumero">Consulta Numero</option>
          </select>
        </div>
        <div>
          <button
            @click="list"
            type="button"
            class="mt-9 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center"
          >
            <span class="inline-block mr-2">Buscar</span>
          </button>
        </div>
        <div>
          <a
            :href="`${api}/v1/campanhasLZV2/auditoria/download?token=${$store.state.token}&de=${form.de}&ate=${form.ate}&acao=${form.acao}`"
            role="button"
            type="button"
            class="mt-9 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center"
          >
            <span class="inline-block mr-2">Download</span>
          </a>
        </div>
      </div>
    </form>
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table
              class="min-w-full divide-y divide-gray-200"
            >
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Data/Hora
                  </th>

                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Usuário
                  </th>

                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Ação
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    IP
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Navegador
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="el in listAuditoria" :key="el._id">
                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ moment(el.data).format("DD/MM/YYYY HH:mm") }}
                  </td>
                  <td class="px-6 py-4 ">
                    <span class="block">{{ el.acesso ? el.acesso.nome : '' }}</span>
                    <span class="block">{{ el.acesso ? el.acesso.email : '' }}</span>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ el.modulo }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <textarea
                      readonly
                      class="form-control w-full text-sm"
                      v-model="el.status"
                    ></textarea>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ el.ip }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ el.browser }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination
            v-model="page"
            :per-page="perPage"
            :records="total"
            @paginate="setPage"
            class="pagination"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Breadcrumb from "../../components/Breadcrumbs.vue";

export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      breadcrumb: [{ url: "/relatorios/auditoria", value: "Auditoria" }],
      route: "auditoria",
      apilz: window.location.hostname === 'uno.trestto.com.br' ? 'https://uno.trestto.com.br/api' : process.env.VUE_APP_API,
      moment: moment,
      listAuditoria: [],
      page: 1,
      perPage: 25,
      form: {
        acao: "",
        usuario: "",
        de: moment().startOf("d").format("YYYY-MM-DDTHH:mm"),
        ate: moment().endOf("d").format("YYYY-MM-DDTHH:mm"),
      },
      total: 0,
    };
  },
  methods: {
    async setPage(page) {
      this.page = page;
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;
      let url = `/v1/campanhasLZV2/auditoria/${skip}/${limit}`;
      url += `?de=${this.form.de}&ate=${this.form.ate}&acao=${this.form.acao}`;
      const listReq = await this.$http.get(url);
      this.listAuditoria = listReq.data.data;
      this.total = listReq.data.total;
    },
    async list() {
      this.setPage(1);
    },
  },
  async beforeMount() {
    this.list();
  },
};
</script>
